.filtersContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 16px;
  background: white;
  margin-bottom: 16px;
  box-shadow: 0px 0.333333px 16px rgba(0, 0, 0, 0.01),
    0px 1.25px 5px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
}
.tableContent {
  padding: 16px;
  margin-bottom: 16px;
  background: white;
  box-shadow: 0px 0.333333px 16px rgba(0, 0, 0, 0.01),
    0px 1.25px 5px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  width: 100%;
}
