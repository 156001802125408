@import './variables';

$app-header-height: 48px;

.appHeader {
  width: 100%;
  background-color: white;
  height: $app-header-height;
  position: fixed;
  padding: 4px 20px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  z-index: 3;
  border: 1px solid #e2e2e4;
}

.title {
  color: $blue-2;
  font-size: 16px;
  cursor: pointer;
}

.pageHeader {
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  z-index: 3;
  border: 1px solid #e2e2e4;
}
