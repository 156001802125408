.role {
  p {
    font-size: 16px;
    font-weight: 500;
  }
}

.roleEnviroment {
  display: flex;
  gap: 8px;
}

